import { Box, Grid, Paper, Typography } from "@mui/material";
import { SocialIcon } from "react-social-icons";

export default function Support() {
  return (
    <Grid
      container
      alignContent={"top"}
      justifyContent={"center"}
      height="calc(100vh - 64px)"
    >
      <Grid item xs={12} sm={12} md={10} lg={10} marginTop={3} marginBottom={3}>
        <Typography variant="h3">Support</Typography>
        <Paper sx={{ backgroundColor: "white", color: "black" }} elevation={3}>
          <Box padding={3}>
            <Typography variant="h5">Contact Us</Typography>
            <br /> If you have any questions or concerns please contact us at:
            <br />
            <a href="mailto:support@revive-games.com">
              support@revive-games.com
            </a>
            <br />
            <br />
            <SocialIcon
              url={"https://www.facebook.com/gaming/revivegamesltd"}
            />
            <SocialIcon url={"https://www.instagram.com/revivegamesltd"} />
            <SocialIcon url={"https://twitter.com/revivegamesltd"} />
            <SocialIcon
              url={"https://www.youtube.com/channel/UCI5tEeHG_C84_xdE9Ry3jqw"}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}
