import { Grid, Paper, Typography } from "@mui/material";
import homeshot from "../../home_shot.png";

export default function Home() {
  return (
    <Grid
      container
      alignContent={"center"}
      justifyContent={"center"}
      paddingTop={3}
    >
      <Grid item xs={11} sm={11} md={11} lg={11}>
        <Paper
          sx={{
            height: "calc(100vh - 100px)",
            backgroundImage: `url(${homeshot})`,
            backgroundRepeat: "no-repeat no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "25% 30%",
          }}
        >
          <Grid container alignContent={"center"} justifyContent={"center"}>
            <Typography
              sx={{
                typography: {
                  sm: "h1",
                  xs: "h2",
                  fontFamily: "DonutRun !important",
                },
                textShadow:
                  "-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff",
                fontFamily: "DonutRun",
              }}
              fontFamily={"DonutRun"}
              color={"#DF65BC"}
              marginTop={2}
            >
              DonutRun
            </Typography>
          </Grid>

          <Grid container alignContent={"center"} justifyContent={"center"}>
            <Typography
              variant="h3"
              fontFamily={"DonutRun"}
              color={"white"}
              style={{
                textShadow:
                  "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
              }}
            >
              Out Now!
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent={"center"}
            alignItems={"center"}
            direction={"row"}
            marginTop={33}
          >
            <>
              <a
                href="https://apps.apple.com/us/app/donutrun/id1586443150?itsct=apps_box_badge&itscg=30200"
                style={{
                  display: "inline-block",
                  overflow: "hidden",
                  borderRadius: "13px",
                  width: "250px",
                  height: "83px",
                }}
              >
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&releaseDate=1674604800"
                  alt="Download on the App Store"
                  style={{
                    borderRadius: "13px",
                    width: "250px",
                    height: "83px",
                  }}
                />
              </a>
            </>
            <a href="https://play.google.com/store/apps/details?id=com.ryansutcliffe.donutrun&hl=en_GB&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                style={{ marginTop: "6px" }}
                height={120}
                width={290}
                src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
              />
            </a>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
