import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import { Route, Routes } from "react-router-dom";
import Home from "./components/home/home";
import Navbar from "./components/navbar/navbar";
import Privacy from "./components/privacy/privacy";
import Support from "./components/support/support";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#fff",
    },

    mode: "dark",
  },
});

export default function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <Box
          sx={{
            height: "calc(100vh - 64px)",
            overflow: "auto",
            background: "linear-gradient(to right bottom, #7c0032, #050056)",
          }}
        >
          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path={"/privacy"} element={<Privacy />} />
            <Route path={"/support"} element={<Support />} />
          </Routes>
        </Box>
      </ThemeProvider>
    </div>
  );
}
